import { isPlatform } from '@ionic/react';
import * as Constants from "./Constants"
import { NativeSettings, AndroidSettings, IOSSettings } from "capacitor-native-settings";

export const isApp = () => {
   const isAnApp = false;
   const result = isPlatform('cordova') || isPlatform('capacitor') || (isAnApp);
   return result;
}

export const isTabletOrDesktop = () => {
   return (isPlatform("desktop") && !isPlatform("capacitor"));
}

export const isTabletOrDesktopNative = () => {
   return ((isPlatform("desktop") || isPlatform("tablet")) && isPlatform("capacitor"))
}

export const isApple = () => {
   return (isPlatform("capacitor") && !isPlatform("android"));
}

export const canShowAppInstallerAlert = () => {
   if (!isApp() && (isPlatform("ios") || isPlatform("android"))) {
       return true
   }
   else {
       return false
   }
}

export const formatDate = (date) => {
   const d = new Date(date)
   const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' })
   const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d)
   return `${mo} ${da}, ${ye}`
}

// export const capitalizeFirstLetter = (string) => {
//    if (typeof string == undefined) return;
//    var firstLetter = string[0] || string.charAt(0);
//    //console.log(firstLetter  ? firstLetter.toUpperCase() + string.substr(1) : '')
//    return firstLetter ? firstLetter.toUpperCase() + string.substr(1) : '';
// }

export const getStringBetween = (str, start, end) => {
   const result = str.match(new RegExp(start + "(.*)" + end));
   return result[1];
}

export const createDynamicLink = (route, amv, imv) => {
   let result = "";
   result = Constants.FIREBASE_DYNAMIC_LINK
   result += "?link=" + Constants.GEOACTIVITY_RIGS_APP_DOMAIN
   result += encodeURIComponent(route)
   result += "&apn=" + Constants.APP_BUNDLE_ID + "&amv=" + amv
   result += "&isi=1156671015"
   result += "&ibi=" + Constants.APP_BUNDLE_ID + "&imv=" + imv + "efr=1"
   return result;
}



// export function retry(fn, retriesLeft = 5, interval = 1000) {
//    return new Promise((resolve, reject) => {
//       fn()
//          .then(resolve)
//          .catch((error) => {
//             setTimeout(() => {
//                if (retriesLeft === 1) {
//                   // reject('maximum retries exceeded');
//                   reject(error);
//                   return;
//                }

//                // Passing on "reject" is the important part
//                retry(fn, retriesLeft - 1, interval).then(resolve, reject);
//             }, interval);
//          });
//    });

//    /*
//    createJSONToSendWithRigID(17, id, rigStatus)
//     */
// }

// export function isInViewport(element) {
//    const rect = element.getBoundingClientRect();
//    return (
//       rect.top >= 0 &&
//       rect.left >= 0 &&
//       rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
//       rect.right <= (window.innerWidth || document.documentElement.clientWidth)
//    );
// }

export const callSettings = (e) => {
   e.preventDefault();
   if (isPlatform("ios")) {
      NativeSettings.openIOS({
         option: IOSSettings.App,
      });
   }
   if (isPlatform("android")) {
      NativeSettings.openAndroid({
         option: AndroidSettings.ApplicationDetails,
      });
   }
   return false;
}
