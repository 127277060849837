import React from 'react';
import ReactDOM from 'react-dom';
//import * as ReactDOMClient from 'react-dom/client'
import './index.css';
import AppMain from './App';
//import * as serviceWorker from './serviceWorker';

ReactDOM.render(<AppMain />, document.getElementById('root'));

// const root = ReactDOMClient.createRoot(
//     document.getElementById('root')
// );
// root.render(
//     <React.StrictMode>
// <AppMain />
// </React.StrictMode>
// )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
