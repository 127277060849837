import { action, thunk } from "easy-peasy";
import { Network } from '@capacitor/network';
import { Share } from '@capacitor/share';
//import { FirebaseDynamicLinks } from 'firebase-dynamic-links';
//import * as Constants from "../utils/Constants"

const utilsModel = {
  networkConnected: null,
  networkConnectionType: null,
  isAdsenseLoaded: false,
  shortDynamicLink: "",

  setNetworkConnected: action((state, isConnected) => {
    state.networkConnected = isConnected;
  }),

  getNetworkStatus: thunk(async (actions) => {
    const status = await Network.getStatus();
    actions.setNetworkConnected(status.connected)
  }),

  setShortDynamicLink: action((state, shortDynamicLink) => {
    state.shortDynamicLink = shortDynamicLink;
  }),



  // createShortLink: (async (actions, payload) => {
  //   actions.setShortDynamicLink("");
  //   const firebaseDynamicLinks = new FirebaseDynamicLinks(Constants.FIREBASE_WEB_API_KEY);
  //   const { shortLink, previewLink } = await firebaseDynamicLinks.createLink({
  //     longDynamicLink: 'https://example.page.link/?link=https://www.example.com/&apn=com.example.android&ibi=com.example.ios',
  //   });
  //   console.log("shortLink, previewLink", shortLink, previewLink)
  //   actions.setShortDynamicLink(shortLink);
  // }),

  shareIt: thunk(async (actions, payload) => {
    try {
      if (await Share.canShare()) {
        await Share.share({
          title: payload.title,
          text: payload.text,
          url: payload.url,
          dialogTitle: payload.dialogTitle
        });
      }
    } catch (error) {
      console.log(error);
    }
  }),

};
export default utilsModel;