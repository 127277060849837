import { thunk, action, computed } from "easy-peasy";
import { mapItemExt } from '../models/MapItemsModels';
import axios from "axios";

const notificationModel = {
  notifications: [],
  notificationsSeen: false,
  hasPaidSubscription: computed(
    [
      (state, storeState) => storeState.subscription.hasPaidSubscription,
    ],
    (hasPaidSubscription) => {
      return hasPaidSubscription;
    }),

  setNotificationsSeen: action((state, notificationsSeen) => {
    state.notificationsSeen = notificationsSeen;
  }),

  setNotifications: action((state, notifications) => {
    state.notifications = notifications;
  }),

  getNotifications: thunk(async (actions, payload) => {
    const notifications = await svc_getNotifications(payload);
    actions.setNotifications(notifications);
  })
}

const svc_getNotifications = async (uuid) => {
  let notificationsResult = [];
  await axios.get(
    `https://us-central1-geoactivityrigsapp.cloudfunctions.net/getUserNotifications/${uuid}`
  )
    .then((result) => {
      const notificationsData = result.data.data;
      for (let index = 0; index < notificationsData.length - 1; index++) {
        const item = notificationsData[index];
        notificationsResult.push(
          new mapItemExt(item.id,
            item.name, null, null, null, null,null,null,
            item.provinceDesc,
            item.statusDate, null, null)
        )
      }
    })
    .catch(function (error) {
      if (axios.isCancel(error)) {
        //console.log('Request canceled', error.message);
      } else {
        console.log("Request Error", error.message);
      }
    });
  return notificationsResult;
};

export default notificationModel;
