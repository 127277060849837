import React, { Suspense } from "react";
import { setupIonicReact } from '@ionic/react';
import { IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { HelmetProvider } from "react-helmet-async";

//import Settings from "./pages/SettingsPage";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "./theme/variables.css";
import "./App.css";

//import MainTabs from "./pages/MainTabs";
import LoadingSpinner from "./components/Shared/LoadingSpinner";
import store from "./store/store";
import { StoreProvider } from "easy-peasy";

//import WebRoutes from "./components/Shared/WebRoutes";
//import AppRoutes from "./components/Shared/AppRoutes";

//import FireBase from "./configs/FireBase"
//const WebRoutes = React.lazy(() => import("./components/Shared/WebRoutes"));
const AppRoutes = React.lazy(() => import("./components/Shared/AppRoutes"));


const AppMain = () => {
  setupIonicReact({})
  return (
    // <React.StrictMode>
    <IonApp>
      <StoreProvider store={store}>
        <HelmetProvider>
          <IonReactRouter>
            <Suspense fallback={<LoadingSpinner/>}>
              {/* {!isApp()
                ? <WebRoutes />  
                : */}
                <AppRoutes />
              {/* }  */}
            </Suspense>
          </IonReactRouter>
        </HelmetProvider>
      </StoreProvider>
    </IonApp>
    //{/* </React.StrictMode> */}
  );
};

export default AppMain;
