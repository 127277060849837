import { action, thunk } from "easy-peasy";
import { InAppPurchase2 } from "@awesome-cordova-plugins/in-app-purchase-2";
import { Preferences as Storage } from "@capacitor/preferences";
import * as Constants from "../utils/Constants";
import { isPlatform } from "@ionic/react"
import { isApple } from "../utils/Utils"


const subscriptionModel = {
  //isProVersion: true,
  store: InAppPurchase2,
  monthlySubscriptionPrice: 0,
  yearlySubscriptionPrice: 0,
  weeklySubscriptionPrice: 0,
  initialized: false,
  isProcessingSubscription: false,
  hasPaidSubscription: false,
  orderCancelled: false,
  payWallShown: false,
  //it is true after the user selects a region in onboarding
  isRegionSelected: false,
  subscriptionList: [
    {
      name: "Monthly Plan",
      price: "$9.99",
      isSelected: false,
      frequency: "Monthly"
    },
    {
      name: "Annual Plan",
      price: "$89.99",
      isSelected: true,
      frequency: "Yearly"
    },
    {
      name: "Weekly Plan",
      price: "$4.99",
      isSelected: false,
      frequency: "Weekly"
    }
  ],

  selectedSubscriptionName: "Annual Plan",
  selectedSubscription: Constants.YEARLY_SUBSCRIPTION,

  setSelectedSubscriptionName: action((state, value) => {
    state.selectedSubscriptionName = value;
  }),

  setSelectedSubscription: action((state, value) => {
    state.selectedSubscription = value;
  }),

  setIsRegionSelected: action((state, value) => {
    state.isRegionSelected = value;
  }),

  init: thunk(async (actions, payload, helpers) => {
    /** just for debugging **/
    const state = helpers.getState();
    state.store.verbosity = state.store.QUIET;//state.store.QUIET;//DEBUG
    registerProducts(state.store);
    setupListeners(actions, helpers);
  }),

  // buy: action((state, subscriptionType) => {
  //   try {
  //     if (isPlatform("android")) {
  //       if (subscriptionType === Constants.MONTHLY_SUBSCRIPTION) {
  //         state.store.order(Constants.ANDROID_MONTHLY_SUBSCRIPTION);
  //       }
  //       else if (subscriptionType === Constants.YEARLY_SUBSCRIPTION) {
  //         state.store.order(Constants.ANDROID_YEARLY_SUBSCRIPTION);
  //       }
  //     }
  //     else if (isPlatform("ios")) {
  //       if (subscriptionType === Constants.MONTHLY_SUBSCRIPTION) {
  //         if (!state.isProVersion) {
  //           state.store.order(Constants.IOS_MONTHLY_SUBSCRIPTION);
  //         }
  //         else {
  //           if (state.store.order) {
  //             console.log("IAP ------------>", state.store, Constants.IOS_MONTHLY_SUBSCRIPTION_PRO)
  //             state.store.order(Constants.IOS_MONTHLY_SUBSCRIPTION_PRO);
  //            // .then(() => { console.log("IAP Order Succesful") })
  //            // .error((err)=>{console.log("IAP Order Error:", err)});
  //           }
  //         }
  //       }
  //       else if (subscriptionType === Constants.YEARLY_SUBSCRIPTION) {
  //         if (!state.isProVersion)
  //           state.store.order(Constants.IOS_YEARLY_SUBSCRIPTION);
  //         else
  //           state.store.order(Constants.IOS_YEARLY_SUBSCRIPTION_PRO);
  //       }
  //     }
  //   }
  //   catch (error) {
  //     console.log("IAP buy error>>>>>>>>>>>>>>>>>:", error)
  //   }
  // }),

  buy: thunk(async (actions, subscriptionType, helpers) => {
    const state = helpers.getState();
    actions.setOrderCancelled(false);
    try {
      if (isPlatform("android")) {
        if (subscriptionType === Constants.MONTHLY_SUBSCRIPTION) {
          state.store.order(Constants.ANDROID_MONTHLY_SUBSCRIPTION);
        }
        else if (subscriptionType === Constants.YEARLY_SUBSCRIPTION) {
          state.store.order(Constants.ANDROID_YEARLY_SUBSCRIPTION);
        }
        else if (subscriptionType === Constants.WEEKLY_SUBSCRIPTION) {
          state.store.order(Constants.ANDROID_WEEKLY_SUBSCRIPTION);
        }
        else if (subscriptionType === Constants.YEARLY_DISCOUNT_SUBSCRIPTION) {
          state.store.order(Constants.ANDROID_YEARLY_SUBSCRIPTION_3399);
        }
      }
      else if (isApple()) {
        if (subscriptionType === Constants.MONTHLY_SUBSCRIPTION) {
          if (state.store.order) {
            state.store.order(Constants.IOS_MONTHLY_SUBSCRIPTION_PRO)
              .then((p) => { console.log("IAP Order Successful xxxx: ", p) })
              .error((err) => { console.log("IAP Order Error:", err) });
          }
          //}
        }
        else if (subscriptionType === Constants.YEARLY_SUBSCRIPTION) {
          state.store.order(Constants.IOS_YEARLY_SUBSCRIPTION_PRO);
        }
        else if (subscriptionType === Constants.WEEKLY_SUBSCRIPTION) {
          state.store.order(Constants.IOS_WEEKLY_SUBSCRIPTION_PRO);
        }
        else if (subscriptionType === Constants.YEARLY_DISCOUNT_SUBSCRIPTION) {
          state.store.order(Constants.IOS_YEARLY_DISCOUNT_SUBSCRIPTION_PRO);
        }
      }
    }
    catch (error) {
      console.log("IAP buy error>>>>>>>>>>>>>>>>>:", error)
    }
  }),

  restorePurchases: thunk(async (actions, payload, helpers) => {
    const state = helpers.getState();
    state.store.restorePurchases();
  }),

  setMonthlySubscriptionPrice: action((state, value) => {
    if (state.monthlySubscriptionPrice !== value)
      state.monthlySubscriptionPrice = value;
  }),

  setYearlySubscriptionPrice: action((state, value) => {
    if (state.yearlySubscriptionPrice !== value)
      state.yearlySubscriptionPrice = value;
  }),

  setWeeklySubscriptionPrice: action((state, value) => {
    if (state.weeklySubscriptionPrice !== value)
      state.weeklySubscriptionPrice = value;
  }),

  setIsProcessingSubscription: action((state, value) => {
    state.isProcessingSubscription = value;
  }),

  setOrderCancelled: action((state, value) => {
    state.orderCancelled = value;
  }),

  setInitialized: action((state, value) => {
    state.initialized = value;
  }),

  setHasPaidSubscription: action((state, value) => {
    state.hasPaidSubscription = value;
  }),

  toogleSubscriptionList: action((state, subscriptionName) => {
    for (let i = 0; i < state.subscriptionList.length; i++) {
      if (state.subscriptionList[i].name === subscriptionName) {
        state.subscriptionList[i].isSelected = true;
      }
      else {
        state.subscriptionList[i].isSelected = false;
      }
    }
  }),

  toogleSubscriptionListPrice: action((state, payload) => {
    for (let i = 0; i < state.subscriptionList.length; i++) {
      console.log("toogleSubscriptionListPrice >>>>>>>>>>>>> 1", payload);
      if (state.subscriptionList[i].frequency === payload.frequency) {
        state.subscriptionList[i].price = payload.price;
        break;
      }
    }
  }),

  setPayWallShown: action((state, value) => {
    state.payWallShown = value;
  }),

  readPayWallShown: thunk(async (actions) => {
    try {
      const { value } = await Storage.get({ key: Constants.MainPayWallShown });
      if (JSON.parse(value)) {
        actions.setPayWallShown(true);
      }
    } catch (error) {
      console.log("error reading mainPayWallShown " + error)
    }

  }),

  persistPayWallShown: thunk(async (actions, payload) => {
    try {
      const valueToSave = JSON.stringify(payload)
      await Storage.set({ key: Constants.MainPayWallShown, value: valueToSave });
      actions.setPayWallShown(payload);
    } catch (error) {
      console.log("error saving mainPayWallShown " + error)
    }
  })
};

const registerProducts = (store) => {
  // Setup the receipt validator service.
  store.validator = 'https://validator.fovea.cc/v1/validate?appName=ca.geoactivity.geoactivityrigs.mobilepro&apiKey=8ab61cb4-838c-4fa1-8433-35c30801f9c4';
  if (isPlatform("android")) {
    store.register({
      id: Constants.ANDROID_MONTHLY_SUBSCRIPTION,
      type: store.PAID_SUBSCRIPTION,
    });

    store.register({
      id: Constants.ANDROID_MONTHLY_SUBSCRIPTION_499,
      type: store.PAID_SUBSCRIPTION,
    });

    store.register({
      id: Constants.ANDROID_YEARLY_SUBSCRIPTION,
      type: store.PAID_SUBSCRIPTION,
    });

    store.register({
      id: Constants.ANDROID_WEEKLY_SUBSCRIPTION,
      type: store.PAID_SUBSCRIPTION,
    });

    store.register({
      id: Constants.ANDROID_YEARLY_SUBSCRIPTION_3399,
      type: store.PAID_SUBSCRIPTION,
    });

    store.register({
      id: Constants.ANDROID_YEARLY_SUBSCRIPTION_5999,
      type: store.PAID_SUBSCRIPTION,
    });

  }

  if (isApple()) {
    store.register({
      id: Constants.IOS_MONTHLY_SUBSCRIPTION_PRO,
      type: store.PAID_SUBSCRIPTION,
    });

    store.register({
      id: Constants.IOS_YEARLY_SUBSCRIPTION_PRO,
      type: store.PAID_SUBSCRIPTION,
    });

    store.register({
      id: Constants.IOS_WEEKLY_SUBSCRIPTION_PRO,
      type: store.PAID_SUBSCRIPTION,
    });
    //IOS_YEARLY_DISCOUNT_SUBSCRIPTION_PRO
    store.register({
      id: Constants.IOS_YEARLY_DISCOUNT_SUBSCRIPTION_PRO,
      type: store.PAID_SUBSCRIPTION,
    });
  }
  store.refresh();
};

const isAMontlySubscription = (id) => {
  return id === Constants.ANDROID_MONTHLY_SUBSCRIPTION ||
    id === Constants.IOS_MONTHLY_SUBSCRIPTION ||
    id === Constants.IOS_MONTHLY_SUBSCRIPTION_PRO ||
    id === Constants.ANDROID_MONTHLY_SUBSCRIPTION_499;
}
const isAYearlySubscription = (id) => {
  return id === Constants.ANDROID_YEARLY_SUBSCRIPTION ||
    id === Constants.IOS_YEARLY_SUBSCRIPTION ||
    id === Constants.IOS_YEARLY_SUBSCRIPTION_PRO ||
    id === Constants.ANDROID_YEARLY_SUBSCRIPTION_3399 ||
    id === Constants.ANDROID_YEARLY_SUBSCRIPTION_5999 ||
    id === Constants.IOS_YEARLY_DISCOUNT_SUBSCRIPTION_PRO;
}
const isAWeeklySubscription = (id) => {
  return id === Constants.IOS_WEEKLY_SUBSCRIPTION_PRO ||
    id === Constants.ANDROID_WEEKLY_SUBSCRIPTION;
}
const setupListeners = (
  actions,
  helpers
) => {
  // General query to all products

  let state = helpers.getState();
  state.store.error((err) => {
    console.error('Store Error >>>>>>>>>>>>> ' + JSON.stringify(err));
    console.log("setHasPaidSubscription >>>>>>>>>>>>> 1", err);
    actions.setHasPaidSubscription(true);
  });
  state.store
    .when("subscription")
    .updated((p) => {
      console.log("product info >>>>>>>>>>>>> 1", p);
      if (!state.hasPaidSubscription && p.owned === true) {
        console.log("setHasPaidSubscription >>>>>>>>>>>>> 2", p);
        actions.setHasPaidSubscription(true);
      }
      if (isAMontlySubscription(p.id)) {
        state = helpers.getState();
        if (p.price !== state.monthlySubscriptionPrice) {
          actions.setMonthlySubscriptionPrice(p.price);
          actions.toogleSubscriptionListPrice({ frequency: "Monthly", price: p.price });
        }
      } else if (isAYearlySubscription(p.id)) {
        state = helpers.getState();
        if (p.price !== state.yearlySubscriptionPrice) {
          if (p.id !== Constants.IOS_YEARLY_DISCOUNT_SUBSCRIPTION_PRO) {
            actions.setYearlySubscriptionPrice(p.price);
            actions.toogleSubscriptionListPrice({ frequency: "Yearly", price: p.price });
          }
        }
      } else if (isAWeeklySubscription(p.id)) {
        state = helpers.getState();
        if (p.price !== state.weeklySubscriptionPrice) {
          actions.setWeeklySubscriptionPrice(p.price);
          actions.toogleSubscriptionListPrice({ frequency: "Weekly", price: p.price });
        }
      }
    })
    .approved((p) => {
      if (isAMontlySubscription(p.id) || isAWeeklySubscription(p.id) || isAYearlySubscription(p.id)) {
        p.verify()
        state = helpers.getState();
        if (!state.initialized) {
          actions.setInitialized(state, true);
        }
        if (p.state === "owned") {
          state = helpers.getState();
          if (state.hasPaidSubscription === false) {
            actions.setHasPaidSubscription(true);
          }
        }
      }
      return;
    })
    .owned((p) => {
      if (isAMontlySubscription(p.id) || isAWeeklySubscription(p.id) || isAYearlySubscription(p.id)) {
        if (p.state === "owned") {
          const settingsState = helpers.getStoreState().settings;
          if (settingsState.hadTrial === false) {
            const settingsActions = helpers.getStoreActions().settings;
            settingsActions.setHadTrial(true);
          }
          state = helpers.getState();
          if (!state.hasPaidSubscription) {
            actions.setHasPaidSubscription(true);
          }
        }
      }
    })
    .verified((p) => {
      if (p.state === "approved") {
        actions.setHasPaidSubscription(true);
      }
      p.finish();
    })
    .cancelled(() => {
      actions.setOrderCancelled(true);
    })
    .error((p) => {
      //actions.setOrderCancelled(false);
      console.log("error xxxxx => : ", p)
      //in case there is an error validating the subscription, we will assume it is valid
      console.log("setHasPaidSubscription >>>>>>>>>>>>> 5");
      actions.setHasPaidSubscription(true);
    });
  //state.store.autoFinishTransactions  = true;
};

export default subscriptionModel;
