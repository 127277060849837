import {
    limit, collection,
    collectionGroup, updateDoc, addDoc,
    query, where, getDocs, deleteDoc
}
from "firebase/firestore";

export class Session {
    constructor(session) {
        this.uid = session.uid;
        this.token = session.token;
        this.deviceName = (session.deviceName) ? session.deviceName : null;
        this.createdOn = Date.now();
        this.updatedOn = Date.now();
    }

    static toJson(session) {
        return {
            uid: session.uid,
            token: session.token,
            deviceName: session.deviceName,
            createdOn: session.createdOn,
            updatedOn: session.updatedOn
        }
    }

    static async save(db, session) {
        if ((db) && (session)) {
            try {
                const userSession = query(collectionGroup(db, 'sessions'),
                    where('uid', '==', session.uid),
                    where('deviceName', '==', session.deviceName), limit(1));
                const docsSnap = await getDocs(userSession);
                if (docsSnap.docs.length === 1 &&
                    (docsSnap.docs[0].token !== session.token
                        || docsSnap.docs[0].deviceName !== session.deviceName)) {
                    try {
                        docsSnap.forEach((doc) => {
                            updateDoc(doc.ref, {
                                token: session.token,
                                updatedOn: Date.now()
                            })
                        });
                    } catch (error) {
                        console.log(error);
                        return false;
                    }
                }
                else {
                    const userSession = Session.toJson(session)
                    const sessionsRef = collection(db, 'users', userSession.uid, 'sessions');
                    await addDoc(sessionsRef, userSession);
                }
            } catch (error) {
                console.log("=>>> :",error);
            }
            return false;
        }
    }

    static async removeDoc(doc) {
        await deleteDoc(doc.ref)
    }

    static async delete(db, session) {
        if ((db) && (session.uid)) {
            try {
                const userSession = query(collectionGroup(db, 'sessions'),
                    where('uid', '==', session.uid),
                    where('deviceName', '==', session.deviceName));
                const docsSnap = await getDocs(userSession);
                docsSnap.forEach(doc => {
                    Session.removeDoc(doc)
                })
            } catch (error) {
                console.info(error)
                return false;
            }
        }
    }
}

