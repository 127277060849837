import { formatDate } from "../utils/Utils"

class LocationItem {
  constructor(id, name, lat, lon) {
    this.id = id;
    this.name = name;
    this.lat = lat;
    this.lon = lon;
  }
}

export class mapItem extends LocationItem {
  constructor(id, name, lat, lon, statusId, itemType, distance, caption) {
    super(id, name, lat, lon);
    this.statusId = statusId;
    this.itemType = itemType;
    this.distance = distance;
    this.caption = caption;
  }
}

export class mapItemExt extends mapItem {
  constructor(id, name, lat, lon, statusId, itemType, distance, caption,provinceDesc, statusDate, grouByValue, fields) {
    super(id, name, lat, lon, statusId, itemType, distance, caption);
    this.provinceDesc = provinceDesc;
    this.statusDate = statusDate;
    this.grouByValue = grouByValue;
    this.fields = fields;
  }
}

export class rigDetails extends mapItem {
  constructor(item) {
    super(item.id, item.name, item.lat, item.lon, item.statusId, item.itemType);
    this.statusDesc = item.statusDesc;
    this.wellId = item.wellId;
    this.statusDate = item.statusDate;
    this.capacity = item.capacity;
    this.contactPersonName = item.contactPersonName;
    this.contactPersonEmail = item.contactPersonEmail;
    this.phone = item.phone;
    this.webSite = item.webSite;
    this.drawworks = item.drawworks;
    this.address = item.address;
    this.days = item.days
  }
}

export class searchGroup {
  constructor(typeDesc, items) {
    this.typeDesc = typeDesc;
    this.items = items
  }
}


export class wellLicense {
  constructor(item) {
    this.id = item.id;
    this.wellUWI = item.wellUwi;
    this.licenseNumber = item.licenseNumber;
    this.licenseeDesc = item.licenseeDesc;
    this.wellName = item.wellName;
    this.substanceDesc = item.substanceDesc;
    this.licenseDate = formatDate(item.licenseDate);
    this.spudDate = (item.spudDate)?formatDate(item.spudDate):null;
    this.profile = item.drillingOperationDesc;
    this.field = item.fieldDesc;
    this.formation = item.formationDesc;
    this.projectedDepth = item.projectedDepth;
    this.lat = item.surfLatitude83;
    this.lon = item.surfLongitude83;
  }
}

