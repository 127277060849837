const itemIcons = [
    [
        require("../assets/icons/license_16.png"),
        require("../assets/icons/license_32.png"),
    ],
    [
        require("../assets/icons/well_16.png"),
        require("../assets/icons/well_32.png"),
    ]
];

const rigIcons = [
    [
        //drilling status
        require('../assets/icons/place_green_32.png'),
        require('../assets/icons/place_green_64.png'),
    ],
    [
        //down status
        require("../assets/icons/place_32.png"),
        require("../assets/icons/place_64.png"),
    ],
    [
        //moving status
        require("../assets/icons/place_32.png"),
        require("../assets/icons/place_64.png"),
    ]
];

class LatlonItem {
    constructor(id, name, lat, lon, typeDesc) {
        this.id = id;
        this.name = name;
        this.lat = lat;
        this.lon = lon;
        this.typeDesc = typeDesc;
    }
}

export class Item extends LatlonItem {
    constructor(id, name, lat, lon, typeDesc, fields, itemStatus) {
        super(id, name, lat, lon, typeDesc);
        this.itemStatus = itemStatus;
        this.fields = fields;
    }
    ItemToMarker() {
        let icons;
        if (this.typeDesc === "License") {
            icons = itemIcons[0]
        } else
            if (this.typeDesc === "Well") {
                icons = itemIcons[1]
            }
        if (this.typeDesc == "Rig") {
            icons = rigIcons;
        }
        let options = null;
        if (this.itemStatus === 0) {
            options = {
                url: { 1: icons[0][0], 2: icons[0][1] },
                anchorOffset: new DOMPoint(0, -16),
            }
        } else {
            options = {
                url: { 1: icons[1][0], 2: icons[1][1] },
                anchorOffset: new DOMPoint(0, -16),
            }
        }
        //need a way to increase zIndex in ImageAnnotation, so rigs always show on top
        //the code bellow doesn't work
        // if (this.typeDesc =="Rig") {
        //     options.displayPriority = 1000;
        //     options.zPriority=1000;
        //     options.zIndex=1000;
        // }

        var coordinate = new window.mapkit.Coordinate(this.lat, this.lon);
        var marker = new window.mapkit.ImageAnnotation(coordinate, options);
        marker.id = this.id;
        return marker;
    }
}