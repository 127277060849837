import { thunk, action, computed } from "easy-peasy";
import { mapItemExt } from '../models/MapItemsModels';
import axios from "axios";
import * as Constants from "../utils/Constants";

const activityModel = {
  currentIndex: -1,
  indexMap: new Map(),
  countOfFRigDetailsShowing: 0,
  activeRigs: null,
  activeRigsGrouped: null,
  groups: null,
  groupCounts: null,
  selectedFilter:"ALL",
  selectedHomeViewValue: Constants.SPUDDATE_VIEW_VALUE,
  rigsByProvinces: null,
  rigsByContractors: null,
  zoomTo: null,
  hasPaidSubscription: computed(
    [
      (state, storeState) => storeState.subscription.hasPaidSubscription,
    ],
    (hasPaidSubscription) => {
      return hasPaidSubscription;
    }),

  provinces: {
    AB: {
      provinceDesc: "Alberta",
      zoomTo: {
        lat: 55.2764233,
        lon: -114.7715173,
        zoom: 3,
      },
    },
    SK: {
      provinceDesc: "Sakatchewan",
      zoomTo: {
        lat: 52.1397391,
        lon: -106.7167667,
        zoom: 3,
      },
    },
    BC: {
      provinceDesc: "British Columbia",
      zoomTo: {
        lat: 55.334967,
        lon: -123.112607,
        zoom: 3,
      },
    },
    ND: {
      provinceDesc: "North Dakota",
      zoomTo: {
        lat: 46.967066,
        lon: -104.3846197,
        zoom: 3,
      },
    },
    MB: {
      provinceDesc: "Manitoba",
      zoomTo: {
        lat: 49.863732,
        lon: -99.9767502,
        zoom: 3,
      },
    },
  },

  setMap: action((state, newMap) => {
    state.indexMap = newMap;
  }),

  setCurrentIndex: action((state, newIndex) => {
    state.currentIndex = newIndex
  }),

  setCountOfFRigDetailsShowing: action((state) => {
    state.countOfFRigDetailsShowing = state.countOfFRigDetailsShowing + 1
  }),

  setZoomTo: action((state, zoomTo) => {
    state.zoomTo = zoomTo;
  }),

  setRigByProvinces: action((state, rigsByProvinces) => {
    state.rigsByProvinces = rigsByProvinces;
  }),
  // setRigsByContractors: action((state, rigsByContractors) => {
  //   state.rigsByContractors = rigsByContractors;
  // }),

  getRigsByProvinces: thunk(async (actions, payload) => {
    const rigsByProvinces = await svc_getRigsByProvinces(payload);
    actions.setRigByProvinces(rigsByProvinces);
  }),

  setSelectedHomeViewValue: action((state, selectedHomeViewValue) => {
    state.selectedHomeViewValue = selectedHomeViewValue;
  }),

  setActiveRigs: action((state, activeRigs) => {
    state.activeRigs = activeRigs;
  }),

  setActiveRigsGrouped: action((state, activeRigsGrouped) => {
    state.activeRigsGrouped = activeRigsGrouped;
  }),

  setSelectedFilter: action((state, selectedFilter) => {
    state.selectedFilter = selectedFilter;
  }),
  setGroups: action((state, groups) => {
    state.groups = groups;
  }),

  setGroupCounts: action((state, groupCounts) => {
    state.groupCounts = groupCounts;
  }),

  getActiveRigs: thunk(async (actions, payload) => {
    const activeRigs = await svc_getActiveRigsGrouped(payload);
    actions.setActiveRigs(activeRigs);
  }),

  getActiveRigsGrouped: thunk(async (actions, payload) => {
    const {activeRigsGrouped, groups, groupCounts} = await svc_getActiveRigsGroupedNew(payload);
    actions.setActiveRigsGrouped(activeRigsGrouped);
    actions.setGroups(groups)
    actions.setGroupCounts(groupCounts)
  })
}

const svc_getRigsByProvinces = async () => {
  let rigCountResult = [];
  await axios.get(
    "https://garigstablestoragefunctionapp20200310121522.azurewebsites.net/api/rigsbyprovince"
  )
    .then((results) => {
      rigCountResult = results.data;
    })
    .catch(function (error) {
      if (axios.isCancel(error)) {
        //console.log('Request canceled', error.message);
      } else {
        console.log("Search Request Error", error.message);
      }
    });
  return rigCountResult;
};
// const svc_getRigsByContractor = async () => {
//   let rigsByContractorResult = [];
//   //await axios.post("https://garigsapi.azure-api.net/search_api/search?searchVersion=1&search=pd 133",
//   await axios.get(
//     "https://garigstablestoragefunctionapp20200310121522.azurewebsites.net/api/rigsbycontractor"
//   )
//     .then((results) => {
//       rigsByContractorResult = results.data;
//     })
//     .catch(function (error) {
//       if (axios.isCancel(error)) {
//         //console.log('Request canceled', error.message);
//       } else {
//         console.log("RigsByContractor Request Error", error.message);
//       }
//     });
//   return rigsByContractorResult;
// };

const svc_getActiveRigsGrouped = async (groupby) => {
  let items = [];
  await axios
    .get(
      `https://garigstablestoragefunctionapp20200310121522.azurewebsites.net/api/rigsby/${groupby}`
    )
    .then((results) => {
      results.data.forEach((group) => {
        let newLocationItem = new mapItemExt(
          group.group,
          group.group,
          null,
          null,
          group.rigs.length,
          1,//type 1 = Rigs entity,
          null,
          null,
          group.group,
        );
        items.push(newLocationItem);
        group.rigs.forEach((item) => {
          let newLocationItem = new mapItemExt(
            item.id,
            item.name,
            item.lat,
            item.lon,
            item.statusId,
            1,//type 1 = Rigs entity
            null,
            null,
            item.provinceDesc,
            item.statusDate,
            group.group,
            null
          );
          items.push(newLocationItem);
        });
      });
    })
    .catch(function (error) {
      if (axios.isCancel(error)) {
        //console.log('Request canceled', error.message);
      } else {
        console.log("Search Request Error", error.message);
      }
    });
    
  return items;
};
const svc_getActiveRigsGroupedNew = async (groupby) => {
  const activeRigsGrouped = [];
  const groups = []; 
  const groupCounts = [];
  await axios
    .get(
      `https://garigstablestoragefunctionapp20200310121522.azurewebsites.net/api/rigsby/${groupby}`
    )
    .then((results) => {
      //console.log(groupby, results)
      results.data.forEach((group) => {
        let newLocationItem = new mapItemExt(
          group.group,
          group.group,
          null,
          null,
          null,
          1,//type 1 = Rigs entity,
          null,
          null,
          group.group
        );
        newLocationItem.count = group.rigs.length
        groups.push(newLocationItem);
        groupCounts.push(group.rigs.length)
        group.rigs.forEach((item) => {
          let newLocationItem = new mapItemExt(
            item.id,
            item.name,
            item.lat,
            item.lon,
            item.statusId,
            1,//type 1 = Rigs entity
            null,
            null,
            item.provinceDesc,
            item.statusDate,
            group.group,
            null
          );
          activeRigsGrouped.push(newLocationItem);
        });
      });
    })
    .catch(function (error) {
      if (axios.isCancel(error)) {
        //console.log('Request canceled', error.message);
      } else {
        console.log("Search Request Error", error.message);
      }
    });
  return {activeRigsGrouped, groups, groupCounts};
};

export default activityModel;
