import {
    limit, collection,
    collectionGroup, updateDoc, addDoc,
    query, where, getDocs, deleteDoc
}
from "firebase/firestore";

export class UserSearch {
    constructor(userSearch) {
        this.uid = userSearch.uid;
        this.itemId = userSearch.itemId;
        this.count = 0;
        this.createdOn = Date.now();
        this.updatedOn = Date.now();
    }

    static toJson(userSearch) {
        return {
            uid: userSearch.uid,
            itemId: userSearch.itemId,
            count: userSearch.count,
            createdOn: userSearch.createdOn,
            updatedOn: userSearch.updatedOn
        }
    }

    static async save(db, userSearch) {
        if ((db) && (userSearch)) {
            try {
                const searchedItem = query(collectionGroup(db, 'searches'),
                    where('uid', '==', userSearch.uid),
                    where('itemId', '==', userSearch.itemId), limit(1));
                const docsSnap = await getDocs(searchedItem);
                if (docsSnap.docs.length === 1) {
                    try {
                        docsSnap.forEach((doc) => {
                            updateDoc(doc.ref, {
                                count: doc.data().count + 1,
                                updatedOn: Date.now()
                            })
                        });
                    } catch (error) {
                        console.log(error);
                        return false;
                    }
                }
                else {
                    userSearch.count = 1;
                    const newUserSearch = UserSearch.toJson(userSearch)
                    const searchesRef = collection(db, 'users', userSearch.uid, 'searches');
                    await addDoc(searchesRef, newUserSearch);
                }
            } catch (error) {
                console.log("xxx error saving user search", error);
            }
            return false;
        }
    }

    static async removeDoc(doc) {
        await deleteDoc(doc.ref)
    }

    // static async delete(db, session) {
    //     if ((db) && (session.uid)) {
    //         try {
    //             const userSession = query(collectionGroup(db, 'sessions'),
    //                 where('uid', '==', session.uid),
    //                 where('deviceName', '==', session.deviceName));
    //             const docsSnap = await getDocs(userSession);
    //             docsSnap.forEach(doc => {
    //                 UserSearch.removeDoc(doc)
    //             })
    //         } catch (error) {
    //             console.info(error)
    //             return false;
    //         }
    //     }
    // }
}

