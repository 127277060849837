export const LOGIN_ROUTE = '/login';
export const SIGNUP_ROUTE = '/signup';
export const RIG_MAP_ROUTE = '/tabs/rig-map';
//export const HOME_ROUTE = '/tabs/home'
export const RIG_LIST_ROUTE = '/tabs/rig-list';
export const NOTIFICATIONS_ROUTE = '/tabs/notifications';
export const SEARCH_ROUTE = '/tabs/search';
export const SETTINGS_ROUTE = "/settings";
export const RIG_DETAILS_ROUTE = "/tabs/rig-map/details"
export const FIREBASE_DYNAMIC_LINK = "https://geoactivityrigs.page.link";
export const GEOACTIVITY_RIGS_APP_DOMAIN = "https://rigs.geoactivity.com";
export const APP_BUNDLE_ID ="ca.geoactivity.geoactivityrigs.MobilePro";
export const FIREBASE_WEB_API_KEY ="AIzaSyABUDJ6lZQ-g-CEJ49PXb_hRzvOBEx9tC0"
//export const SEARCH_ROUTE ='/search';
export const NEARBY_LICENSES = "/tabs/rig/nearby-licenses/";
export const NEARBY_WELLS = "/tabs/rig/nearby-wells/";
export const WELLS = "/tabs/wells/";
export const ABOUT_ROUTE = '/about';
//export const RIG_MAP_FAV_ROUTE ='/rig-map/favorites';
export const PASSWORD_RESET_ROUTE = "/recover-password";
export const SUPPORT_ROUTE = "/support";
export const PASSWORD_RESET = "https://rigs.geoactivity.com/login/blank";
export const CONTRACTOR_VIEW_VALUE = "contractor";
export const SPUDDATE_VIEW_VALUE = "spuddate";
export const PROVINCE_VIEW_VALUE = "province";
export const ALL_VIEW_VALUE = "all";
export const FAVORITES_VIEW_VALUE = "favorites";
export const APP_GUID = "APP_GUID";
/*Subscriptions Constants */
export const PLATFORM_ANDROID = "ANDROID";
export const PLATFORM_IOS = "IOS";
export const MONTHLY_SUBSCRIPTION = "Monthly";
export const YEARLY_SUBSCRIPTION = "Yearly";
export const YEARLY_DISCOUNT_SUBSCRIPTION = "Yearly Discount";
export const WEEKLY_SUBSCRIPTION = "Weekly";
export const ANDROID_MONTHLY_SUBSCRIPTION = "ca.riglocations.geoactivity_rigs_pro_999";//Current Monthly Plan
export const ANDROID_MONTHLY_SUBSCRIPTION_499 = "ca.riglocations.geoactivity_rigs_pro_499";//OldPackage Monthly Offerings RevenueCat
export const ANDROID_YEARLY_SUBSCRIPTION_3399 = "ca.riglocations.geoactivity_rigs_pro_year";//Discounted offer, no trial
export const ANDROID_YEARLY_SUBSCRIPTION_5999 = "ca.riglocations.geoactivity_rigs_pro_year_59";//OldPackage Annual Offerings RevenueCat
export const ANDROID_YEARLY_SUBSCRIPTION = "ca.riglocations.geoactivity_rigs_pro_year_7999"//Current Yearly Plan;
export const ANDROID_WEEKLY_SUBSCRIPTION = "ca.riglocations.geoactivity_rigs_weekly";//Current Weekly Plan

export const IOS_MONTHLY_SUBSCRIPTION = "201905032";
export const IOS_YEARLY_SUBSCRIPTION = "201905035";
export const IOS_MONTHLY_SUBSCRIPTION_PRO = "201609212";
export const IOS_YEARLY_SUBSCRIPTION_PRO = "201609215";
export const IOS_WEEKLY_SUBSCRIPTION_PRO = "20230821"
export const IOS_YEARLY_DISCOUNT_SUBSCRIPTION_PRO = "2023092055";//Discounted offer, no trial
export const GOOGLE_MAP_KEY = "AIzaSyBDToTfQXIt8cetzqXAqhd9O-GeeNiy36g";
export const MainPayWallShown = "PAY_WALL_SHOWN";
export const MainPayWallOfferShown = "PAY_WALL_OFFER_SHOWN";
export const OfferTimeLeft = "OFFER_TIME_LEFT";
/*App stores Links */
export const APPLE_APP_STORE = "https://apps.apple.com/us/app/geoactivity-rigs-pro/id1156671015?ls=1";
export const GOOGLE_PLAY_STORE = "https://play.google.com/store/apps/details?id=ca.geoactivity.geoactivityrigs.MobilePro";

/*services urls base*/
export const CORE_SERVICE ="https://grscv2.riglocations.ca/api/";
export const AZURE_RIGS_API ='https://garigsapi.azure-api.net/';
//export const CORE_SERVICE_TEST ="https://test.riglocations.ca/api/";
export const AZURE_FUNC_URL ="https://garigstablestoragefunctionapp20200310121522.azurewebsites.net/";
export const FINDER_FUNC_URL = "https://gafindersearch.azurewebsites.net/";
//export const AZURE_SEARCH_FUNC_URL = "https://garigssearchtest.azurewebsites.net/api/";
export const AZURE_SEARCH_FUNC_URL = "https://garigssearch.azurewebsites.net/api/";

/*RevenueCat*/
export const entitlementId = "premium";
export const appleApiKey = "appl_mLvBFFGcqtuPxbuXtDdkHzVULYu";
export const googleApiKey = "goog_GkQPMsddSKxGfUaRntMDyGNBYIT";

/*NEWS LINKS*/
export const AB_LIC_LIST = "https://feeds.geoactivity.com/activity/Alberta-Well-Licenses?key=1&title=Alberta%20Well%20Licenses&days=";
export const SK_LIC_LIST= "https://feeds.geoactivity.com/activity/Saskatchewan-Well-Licenses?key=3&title=Saskatchewan%20Well%20Licenses&days=";
export const AB_LIC_MAP = "https://www.geoactivity.com/Maps";

export const CAPP = "https://www.capp.ca/";
export const PSAC = "https://www.psac.ca/";
export const CAOEC = "https://caodc.ca/";

export const CALGARY_HERALD = "https://calgaryherald.com/category/business/energy/";
export const AER = "https://www.aer.ca/";
export const BAKKENBLOG ="https://bakkenblog.com/";

export const COINMETRO ="https://coinmetro.com/?ref=crytox";
