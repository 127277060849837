import axios from "axios";
import * as Constants from "../utils/Constants"

class AnalyticsLog {
  constructor(payload) {
    this.guid = payload.guid;
    this.userLongitude = -999;
    this.userLatitude = -9999;
    this.rigId = payload.rigId;
    this.rigLongitude = payload.rigLongitude;
    this.rigLatitude = payload.rigLatitude;
    this.wellId = payload.wellId;
    this.wellLongitude = payload.wellLong;
    this.wellLatitude = payload.wellLat;
    this.typeID = payload.typeID;
    this.notes = payload.notes;
  }
}


const AnalyticsService = async (payload) => {
  const logTypeId = payload.typeID;
  let formattedLog;
  switch (logTypeId) {
    case 17:
      formattedLog = formatJsonPayLoad(payload);
      await sendAnalyticsLog(formattedLog);
      break;
    default:
      console.log("Sorry, we are out of " + logTypeId + ".");
  }
};

const formatJsonPayLoad = (payload) => {

  let jsonData = {
    guid: payload.guid,
    userLongitude: -999,
    userLatitude: -9999,
    rigId: payload.rigId,
    rigLongitude: payload.rigLongitude,
    rigLatitude: payload.rigLatitude,
    wellId: payload.wellId,
    wellLongitude: payload.rigLongitude,
    wellLatitude: payload.rigLatitude,
    typeID: payload.typeID
  };
  let notes = Object.assign({}, jsonData);
  //pending
  // note["mapZoom"] = zoom;
  // note["mapCenterX"] = centerX;
  // note["mapCenterY"] = centerY;
  // note["boundsNEY"] = neLat;
  // note["boundNEX"] = neLng;
  // note["boundsSWY"] = swLat;
  // note["boundSWX"] = swLong;
  // note["buffer"] = 10;
  if (payload.typeID === 17) {
    notes["status"] = payload.fromStatus + "$" + payload.toStatus;
  }
  jsonData["notes"] = JSON.stringify(notes);
  let jsonResult = new AnalyticsLog(jsonData);
  console.log("xxx >>> jsonData", JSON.stringify(jsonResult));
  return jsonResult;

};

const sendAnalyticsLog = async (payload) => {
  //console.log("jsonData",  JSON.stringify(payload))
  await axios
    .post(Constants.CORE_SERVICE + "subscription/SaveLog", payload)
    .then((result) => {
      console.log("result", result)
      console.log(result);
    })
    .catch(function (error) {
      console.log("Search Request Error", error.message);
    });
};

export default AnalyticsService;
