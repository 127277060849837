import { thunk, action, computed } from "easy-peasy";
import { Preferences as Storage } from "@capacitor/preferences";
import * as Constants from "../utils/Constants";
//import axios from "axios";
import { CapacitorHttp } from '@capacitor/core';
import { initializeApp } from "firebase/app"
import { getFirestore, collection, setDoc, doc, getDocFromServer } from "firebase/firestore";
import { Session } from "../models/NotificationModels";
import { Device } from "@capacitor/device";
//import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";

import {
  getAuth,
  initializeAuth,
  indexedDBLocalPersistence,
  signOut,
  signInAnonymously,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

import { getAnalytics } from "firebase/analytics";
import { Capacitor } from '@capacitor/core';


const authModel = {
  fbApp: null,
  fbDb: null,
  analytics: null,
  auth: null,
  referrer: null,
  user: null,
  favoritesRigs: [],
  isAuthenticated: computed((state) => (state.user != null)),
  menuNotifications: computed((state) => {
    if ((state.isAuthenticated) && (state.favoritesRigs)) {
      if (state.favoritesRigs.length > 0) {
        return true
      }
    }
    else return false
  }),
  selectedRigsMapViewValue: "all",
  selectedRigsListViewValue: Constants.SPUDDATE_VIEW_VALUE,
  isFirebase: null,
  isFireBaseInitialized: false,
  pushNotificationSession: null,
  pushNotificationToken: null,
  uuid: null,
  hasPaidSubscription: computed(
    [
      (state, storeState) => storeState.subscription.hasPaidSubscription,
    ],
    (hasPaidSubscription) => {
      return hasPaidSubscription;
    }),

  setUserGeneratedGuid: action((state, uuid) => {
    state.uuid = uuid;
  }),

  getUserGeneratedGuid: thunk(async (actions) => {
    const { value } = await Storage.get({ key: Constants.APP_GUID });
    if (value) {
      actions.setUserGeneratedGuid(value);
    } else {
      await Storage.set({
        key: Constants.APP_GUID,
        value: generateGUID(),
      });
    }
  }),

  setIsFirebaseInitialized: action((state, isFireBaseInitialized) => {
    state.isFireBaseInitialized = isFireBaseInitialized;
  }),

  setPushNotificationToken: action((state, pushNotificationToken) => {
    state.pushNotificationToken = pushNotificationToken;
  }),

  setPushNotificationSession: action((state, pushNotificationSession) => {
    state.pushNotificationSession = pushNotificationSession;
  }),

  setFirebaseApp: action((state, fbApp) => {
    state.fbApp = fbApp;
  }),
  setFirebaseAuth: action((state, auth) => {
    state.auth = auth;
  }),
  setFirebaseDb: action((state, fbDb) => {
    state.fbDb = fbDb;
  }),
  setFireBaseAnalytics: action((state, fbAnalytics) => {
    state.analytics = fbAnalytics;
  }),

  logout: thunk(async (actions, payload, helpers) => {

    const currentState = helpers.getStoreState();
    try {
      if (currentState.auth.isFireBaseInitialized) {
        if (currentState.auth.pushNotificationSession) {
          await Session.delete(currentState.auth.fbDb, currentState.auth.pushNotificationSession);
        }
        if (currentState.auth.auth) {
          await signOut(currentState.auth.auth);
          if (actions.auth) {
            actions.auth.setFirebaseAuth(null)
          }
        }
        actions.setUser(null)
      }
    } catch (error) {
      console.log(error);
    }
  }),

  initFirebase: thunk(async (actions) => {
    const firebaseConfig = {
      apiKey: "AIzaSyABUDJ6lZQ-g-CEJ49PXb_hRzvOBEx9tC0",
      authDomain: "geoactivityrigsapp.firebaseapp.com",
      databaseURL: "https://geoactivityrigsapp.firebaseio.com",
      projectId: "geoactivityrigsapp",
      storageBucket: "geoactivityrigsapp.appspot.com",
      messagingSenderId: "95324122692",
      appId: "1:95324122692:web:c01ab24e704917b3",
      measurementId: "G-TV5NL3WZB8",
    };
    try {
      let fbApp = initializeApp(firebaseConfig);
      let auth
      if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(fbApp, {
          persistence: indexedDBLocalPersistence
        })
      } else {
        auth = getAuth()
      }
      const analytics = getAnalytics();
      actions.setFireBaseAnalytics(analytics)
      actions.setFirebaseApp(fbApp);
      const db = getFirestore(fbApp);
      actions.setFirebaseDb(db);
      actions.setFirebaseAuth(auth)
    } catch (error) {
      /*
       * We skip the "already exists" message which is
       * not an actual error when we're hot-reloading.
       */
      if (!/already exists/.test(error.message)) {
        console.error("Firebase initialization error", error.stack);
      }
    }
    //window.Firebase = firebase;
    actions.setIsFirebaseInitialized(true);
  }),

  setReferrer: action((state, referrer) => {
    state.referrer = referrer;
  }),

  loginAnonymous: thunk(async (actions, payload, helpers) => {
    try {
      const auth = helpers.getStoreState().auth.auth;
      if (auth) {
        await signInAnonymously(auth);
      }
    } catch (error) {
      console.error(error.message);
    }
  }),

  signInWithGoogle: thunk(async(actions, payload, helpers)  => {
    const provider = new GoogleAuthProvider();
    try {
      const auth = helpers.getStoreState().auth.auth;
      if (auth) {
        await signInWithPopup(auth, provider);
      }
    } catch (error) {
      console.error(error.message);
    }
  }),
  
  setSelectedRigsMapViewValue: action((state, selectedRigsMapViewValue) => {
    state.selectedRigsMapViewValue = selectedRigsMapViewValue;
  }),

  setSelectedRigsListViewValue: action((state, selectedRigsListViewValue) => {
    state.selectedRigsListViewValue = selectedRigsListViewValue;
  }),

  addRigToFavorites: action((state, rigId) => {
    if (state.user) {
      const index = state.favoritesRigs.indexOf(rigId);
      if (index === -1) {
        let newFavs = [...state.favoritesRigs]
        newFavs.push(rigId)
        state.favoritesRigs = newFavs;
      }
    }
  }),

  deleteRigFromFavorites: action((state, rigId) => {
    if (state.user) {
      var filtered = state.favoritesRigs.filter(function (value) {
        return value !== rigId;
      });
      state.favoritesRigs = filtered;
    }
  }),

  saveFavoritesRigs: thunk(async (actions, payload, helpers) => {
    const db = helpers.getStoreState().auth.fbDb;
    const auth = helpers.getStoreState().auth;
    if (db) {
      if (auth.user.uid && auth.favoritesRigs) {
        const usersRef = collection(db, "users");
        await setDoc(doc(usersRef, auth.user.uid),
          {
            favoritesRigs: auth.favoritesRigs
          },
          { merge: true });
      }

      const docRef = doc(db, "users", auth.user.uid);
      const docSnap = await getDocFromServer(docRef);
      let data = null;
      if (docSnap.exists()) {
        try {
          data = docSnap.data();
        } catch (error) {
          console.log("xxx-> error inside exists :" + JSON.stringify(data))
        }
      }
    }
  }),

  setFavoritesRigs: action((state, favoritesRigs) => {
    state.favoritesRigs = favoritesRigs;
  }),

  getFavoritesRigs: thunk(async (actions, payload, helpers) => {
    const db = helpers.getStoreState().auth.fbDb;
    const userId = helpers.getStoreState().auth.user.uid;
    let data = null;
    if (db) {
      const docRef = doc(db, "users", userId);
      const docSnap = await getDocFromServer(docRef);
      if (docSnap.hasError) {
        console.log("there are error")
      }
      if (docSnap.exists()) {
        try {
          data = docSnap.data();
        } catch (error) {
          console.log("xxx-> error inside exists :" + JSON.stringify(data))
        }
        if (data.favoritesRigs) {
          actions.setFavoritesRigs(data.favoritesRigs);
        }
      }

    }
  }),

  setUser: action((state, user) => {
    state.user = user;
  }),

  sendEmailSupport: thunk(async (actions, payload) => {
    await svc_sendSupportEmailCap(payload);
  }),

  getSessionInfo: thunk(async (actions, payload, helpers) => {
    const currentState = helpers.getStoreState();
    const db = currentState.auth.fbDb;
    if ((db) && (currentState.auth.user) && (currentState.auth.pushNotificationToken)) {
      const userSession = await getSession(db,
        currentState.auth.user,
        currentState.auth.pushNotificationToken
      );
      if (userSession) {
        actions.setPushNotificationSession(userSession)
      }
    }
  }),
};


const getSession = async (db, user, pushNotificationToken) => {
  const info = await Device.getInfo();
  const payload = {
    uid: user.uid,
    token: pushNotificationToken,
    deviceName: info.name
  }
  const userSession = new Session(payload)
  if (db) {
    await Session.save(db, userSession)
  }
  return userSession;
}

const generateGUID = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

const svc_sendSupportEmailCap = async (requestData) => {
  let result = "";
  const options = {
    url: "https://garemailsupport.azurewebsites.net/api/support-email",
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify({
      senderEmail: requestData.senderEmail,
      subject: requestData.subject,
      message: requestData.message
    })
  };
  try {
    const response = await CapacitorHttp.request({ ...options, method: 'POST' })
    result = response.data;
  } catch (error) {
    console.log("xxx error sending email support", error);
  }
  return result;
}

export default authModel;
