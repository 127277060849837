import {
    PushNotifications
} from "@capacitor/push-notifications";
//not being used
export const registerNotifications = async () => {
    let result = "error"
    let permStatus = await PushNotifications.checkPermissions();

    try {
        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
            throw new Error('User denied permissions!');
        }
        if (permStatus.receive === 'granted') {
            result = "granted";
        }
        await PushNotifications.register();
    } catch (error) {
        console.log(error)
    }
    return result;
}

// export const addListeners = async () => {
//     const setPushNotificationToken = useStoreActions(
//         actions => actions.auth.setPushNotificationToken
//     );
//     await PushNotifications.addListener('registration', token => {
//         setPushNotificationToken(token.value);
//     });

//     await PushNotifications.addListener('registrationError', err => {
//         //alert(err.error);
//     });

//     await PushNotifications.addListener('pushNotificationReceived', notification => {
//         //alert('Push notification received: ' + JSON.stringify(notification));
//     });

//     await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
//         const history = useHistory();
//         // alert('Push notification pushNotificationActionPerformed: ' + JSON.stringify(notification.notification));
//         try {
//             const slug = getStringBetween(decodeURIComponent(notification.notification.data.link), Constants.GEOACTIVITY_RIGS_APP_DOMAIN, "&apn")
//             if (slug)
//                 history.push(slug)
//         } catch (error) {
//             console.log(error)
//         }
//     });
// }
