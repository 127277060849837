import { thunk, action, computed, persist } from "easy-peasy";
import * as Constants from "../utils/Constants"
import axios from "axios";
import { Item } from "../models/AppModels";

const mapModel = persist({
  wellsInBounds: [],
  zoom: null,
  lat: null,
  lon: null,
  //can be a rig, well/license/ Land Grid Location or LatLon
  //can be set by Tapping on the Map or Search
  selectedItem: null,
  //every time a new selected item is set, it will be added to this array
  //maybe we do not need Rigs in this array since they are already in the Rigs array'
  //and are persisted bwtweem maps boundaries and zoom changes
  selectedItems: [],
  //Last area on map that the user was in, it will be restored when open app
  mapRegion: null,
  //reference to Apple Map Object
  rigsMap: null,

  allRigs: [],
  rigs: computed(
    [
      (state) => state.allRigs,
      (state, storeState) => storeState.settings.status,
      (state, storeState) => storeState.auth.selectedRigsMapViewValue,
      (state, storeState) => storeState.auth.favoritesRigs,
    ],
    (allRigs, status, selectedRigsView, favoritesRigs) => {
      
      let result = [];
      try {
        
        if (selectedRigsView === Constants.ALL_VIEW_VALUE) {
          const selectedStatus = status.filter((s) => s.isSelected === true);
          if (allRigs) {
            selectedStatus.forEach((status) => {
              let filtered = allRigs.filter(
                (r) => r.itemStatus === status.statusId
              );
              result = result.concat(filtered);
            });
          }
        } else {
          if (favoritesRigs && favoritesRigs.length > 0) {
            const favRigs = allRigs.filter((r) => favoritesRigs.includes(r.id));
            result = result.concat(favRigs);
          } else {
            result = [];
          }
        }
      } catch (error) {
        console.log("xxx error calculating rigs", error.message);
      }
      return result;
    }
  ),

  // setZoom:action((state, zoom) => {
  //   state.zoom = zoom;
  // }),
  // setCenter:action((state, center) => {
  //   state.center = center;
  // }),

  setRigsMap: action((state, map) => {
    state.rigsMap = map;
  }),

  setSelectedItem: action((state, selectedItem) => {
    state.selectedItem = selectedItem;
  }),

  setAllRigs: action((state, allRigs) => {
    state.allRigs = allRigs;
  }),

  setWellsInBounds: action((state, wellsInBounds) => {
    state.wellsInBounds = wellsInBounds;
  }),

  setMapRegion: action((state, region) => {
    state.mapRegion = region;
  }),

  //thunks
  getRigs: thunk(async (actions, payload) => {
    const rigsR = await svc_getRigs(payload);
    actions.setAllRigs(rigsR);
  }),

  getWellInBounds: thunk(async (actions, mapRegion, helpers) => {
    if ('toBoundingRegion' in mapRegion) {
      const mainMap = helpers.getState().rigsMap;
      const settingsState = helpers.getStoreState().settings;
      const zoom = mainMap._impl.zoomLevel;
      let boundingRegion;
      try {
        boundingRegion = mapRegion.toBoundingRegion();
      } catch (error) {
        console.log(error);
      }


      const payload = {
        "latMin": boundingRegion.southLatitude,
        "latMax": boundingRegion.northLatitude,
        "lonMin": boundingRegion.westLongitude,
        "lonMax": boundingRegion.eastLongitude,
        "zoom": zoom,
        "categoryFilter": "Wells",
        "wellsFilter": settingsState.showWells,
        "licensesFilter": settingsState.showLicenses,
        "moreFilters": ""
      };
      const wellsInBoundsR = await svc_getWells(payload);
      actions.setWellsInBounds(wellsInBoundsR);
    }
  }),
},
  {
    allow: ["mapRegion"],
    storage: 'localStorage'
  }
);

const svc_getRigs = async () => {
  let result = [];
  try {
    await axios.get(Constants.AZURE_RIGS_API + "rigs_api/rigs").then((results) => {
      let data = results.data;
      //let count = 0;
      data.forEach((item) => {
        const newItem = new Item(
          item.id,
          item.name,
          item.lat,
          item.lon,
          "Rig",
          item.fields,
          item.statusId
        );
        //if (count < 5) 
        result.push(newItem);
      });
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

const svc_getWells = async (payload) => {
  var wellsResult = [];
  await axios.post(Constants.FINDER_FUNC_URL + "api/items", payload)
    .then((result) => {
      const items = result.data;
      if (items && items.length > 0) {
        items.forEach(item => {
          const newItem = new Item(item.id, item.caption, item.ycentr, item.xcentr, item.typeDesc, item.fields, "");
          wellsResult.push(newItem)
        });
      }
    })
    .catch(function (error) {
      console.log("well Request Error", error);
    });
  return wellsResult;
};

export default mapModel;