import { thunk, action, computed, persist } from "easy-peasy";
import AnalyticsService from "./analytics-service";
import { registerNotifications } from "../utils/PushNotificationsUtils"
import {  PushNotifications} from "@capacitor/push-notifications";
import { UserSearch } from "../models/UserSearchModel";
//import { AZURE_RIGS_API } from "../utils/Constants";

//import {} from "../assets/icons"
//Type = 1 =>"Rigs";
//Type = 2 =>"Townships - LSD";
//Type = 3 =>"Wells";
//Type = 4 =>"Latitude Longitude";
//Type = 5 =>"Wells License";
import {
  mapItem,
  mapItemExt,
  searchGroup,
  rigDetails,
  wellLicense,
} from "../models/MapItemsModels";
import authModel from "./store-auth-model";
import activityModel from "./store-activity-model";
import utilsModel from "./utils-model";
import subscriptionModel from "./subscriptions-model";
import notificationModel from "./store-notification-model";
import mapModel from "./map-store";
import searchNativeModel from "./search-native-store";
import Axios from "../configs/Axios";
import axios from "axios";
import * as Constants from "../utils/Constants";
import { collection, setDoc, getDoc, doc } from "firebase/firestore";
import { isApp } from "../utils/Utils";
import { isPlatform } from "@ionic/core";

const rigsModel = persist({
  map: null,
  //rigsLoaded: false,
  selectedAnnotation: null,
  
  selectedRig: null,
  selectedRigWell: null,
  itemsInRadius: [],
  isMapInitialized: false,
  mapRegion: null,
  rigsMap: null,
  allRigs: [],
  rigs: computed(
    [
      (state) => state.allRigs,
      (state, storeState) => storeState.settings.status,
      (state, storeState) => storeState.auth.selectedRigsMapViewValue,
      (state, storeState) => storeState.auth.favoritesRigs,
    ],
    (allRigs, status, selectedRigsView, favoritesRigs) => {
      let result = [];
      if (selectedRigsView === Constants.ALL_VIEW_VALUE) {
        const selectedStatus = status.filter((s) => s.isSelected === true);
        if (allRigs) {
          selectedStatus.forEach((status) => {
            let filtered = allRigs.filter(
              (r) => r.statusId === status.statusId
            );
            result = result.concat(filtered);
          });
        }
      } else {
        if (favoritesRigs && favoritesRigs.length > 0) {
          const favRigs = allRigs.filter((r) => favoritesRigs.includes(r.id));
          result = result.concat(favRigs);
        } else {
          result = [];
        }
      }
      return result;
    }
  ),

  icons: {
    rigIcons: [
      [
        //drilling stat
        require('../assets/icons/place_green_32.png'),
        require('../assets/icons/place_green_64.png'),
      ],
      [
        //down status
        require("../assets/icons/place_32.png"),
        require("../assets/icons/place_64.png"),
      ],
      [
        //moving status
        require("../assets/icons/place_32.png"),
        require("../assets/icons/place_64.png"),
      ],
      [
        //selected status, when user select rig in map
        require("../assets/icons/place_red_32.png"),
        require("../assets/icons/place_red_64.png"),
      ],
    ],

    itemIcons: [
      [
        require("../assets/icons/license_16.png"),
        require("../assets/icons/license_32.png"),
      ],
      [
        require("../assets/icons/well_16.png"),
        require("../assets/icons/well_32.png"),
      ]
    ],
  },
  //actions

  setRigsMap: action((state, map) => {
    state.rigsMap = map;
  }),

  setMapRegion: action((state, region) => {
    if (state.mapRegion) {
      if (region) {
        if (state.mapRegion.center.latitude !== region.center.latitude) {
          state.mapRegion = region;
        }
      }
    }
    else {
      state.mapRegion = region;
    }
  }),

  // setRigsLoaded: action((state, rigsLoaded) => {
  //   state.rigsLoaded = rigsLoaded;
  // }),

  setSelectedAnnotation: action((state, selectedAnnotation) => {
    state.selectedAnnotation = selectedAnnotation;
  }),

  setRigs: action((state, rigs) => {
    state.rigs = rigs;
  }),

  setAllRigs: action((state, allRigs) => {
    state.allRigs = allRigs;
  }),

  setSelectedRig: action((state, rig) => {
    state.selectedRig = rig;
  }),

  setSelectedRigWell: action((state, rigWell) => {
    state.selectedRigWell = rigWell;
  }),
  //items are Licenses and/or wells
  setItemsInRadius: action((state, itemsInRadius) => {
    state.itemsInRadius = [];
    state.itemsInRadius = itemsInRadius;
  }),

  setIsMapInitialized: action((state, value) => {
    state.isMapInitialized = value;
  }),

  //thunks
  getRigs: thunk(async (actions, payload) => {
    const rigsR = await svc_getRigs(payload);
    actions.setAllRigs(rigsR);
  }),

  getSelectedRig: thunk(async (actions, payload) => {
    const rigResult = await svc_getRigInfo(payload);
    actions.setSelectedRig(rigResult);
  }),

  getSelectedRigWell: thunk(async (actions, payload) => {
    const rigWellResult = await svc_getRigWell(payload);
    actions.setSelectedRigWell(rigWellResult);
  }),
  //items can be Licenses, wells or boths
  getItemsInRadius: thunk(async (actions, payload) => {
    const itemsInRadius = await svc_getItemsInRadius(payload);
    actions.setItemsInRadius(itemsInRadius);
  }),

  sendAnalytics: thunk(async (actions, payload) => {
    await AnalyticsService(payload);
  }),
},
  {
    allow: ['mapRegion'],
    storage: 'localStorage'
  }
);

const searchModel = {
  searchedItemIcons: [
    require("../assets/icons/location1x.png"),
    require("../assets/icons/location2x.png"),
    require("../assets/icons/location3x.png"),
  ],
  searchText: "",
  searchedItem: null,
  searchedItemAnnotation: null,
  searches: [],
  searchFilters: [],
  selectedSearchFilter: "All",
  //actions
  clearSearches: action((state) => {
    state.searches = [];
  }),
  setSearchText: action((state, searchText) => {
    state.searchText = searchText;
  }),
  setSearches: action((state, searches) => {
    state.searches = searches;
  }),
  setSearchFilters: action((state, searchFilters) => {
    state.searchFilters = searchFilters;
  }),
  setSelectedSearchFilter: action((state, selectedSearchFilter) => {
    state.selectedSearchFilter = selectedSearchFilter;
  }),
  setSearchedItem: action((state, searchedItem) => {
    state.searchedItem = searchedItem;
  }),
  setSearchedItemAnnotation: action((state, searchedItemAnnotation) => {
    state.searchedItemAnnotation = searchedItemAnnotation;
  }),
  //thunks//thunks
  getSearches: thunk(async (actions, payload) => {
    actions.setSearchText(payload.searchValue);
    const searches = await svc_getSearches(payload);
    actions.setSearches(searches);
  }),

  getSearchFilters: thunk(async (actions) => {
    const searchFilters = await svc_getSearchFilters();
    actions.setSearchFilters(searchFilters);
  }),

  saveSearchedItem: thunk(async (actions, item, helpers) => {
    if (item.itemType === 1) {
      const currentState = helpers.getStoreState();
      const db = currentState.auth.fbDb;
      if ((db) && ((currentState.auth.user) && currentState.auth.user.uid)) {
        const userSearch = new UserSearch({
          uid: currentState.auth.user.uid,
          itemId: item.id
        })
        await UserSearch.save(db, userSearch)
      }
    }
  })
};

const settingsModel = persist(
  {
    status: [
      {
        statusDesc: "Drilling",
        statusId: 0,
        isSelected: true,
      },
      {
        statusDesc: "Moving",
        statusId: 2,
        isSelected: false,
      },
      {
        statusDesc: "Down",
        statusId: 1,
        isSelected: false,
      },
    ],
    showAppInstallAlert: true,
    isPushNotificationInitialized: false,
    isPushNotificationEnabled: false,
    isSpudPushNotificationEnabled: true,
    showLicenses: true,
    showWells: true,
    showLandGrid: true,
    //buffer in kms around selected rig
    searchRadius: 20,
    projDepthRangeMinValue: 0,
    projDepthRangeMaxValue: 12000,
    hadTrial: false,
    //actions
    setHadTrial: action((state, hadTrial) => {
      state.hadTrial = hadTrial;
    }),

    showSearchRadius: computed((state) => {
      return state.showLicenses || state.showWells;
    }),

    setShowAppInstallAlert: action((state, showAppsAlert) => {
      state.showAppInstallAlert = showAppsAlert;
    }),

    setIsPushNotificationEnabled: action((state, isPushNotificationEnabled) => {
      state.isPushNotificationEnabled = isPushNotificationEnabled;
    }),

    setIsPushNotificationInitialized: action((state, isPushNotificationInitialized) => {
      state.isPushNotificationInitialized = isPushNotificationInitialized;
    }),

    setIsSpudPushNotificationEnabled: action((state, isSpudPushNotificationEnabled) => {
      state.isSpudPushNotificationEnabled = isSpudPushNotificationEnabled;
    }),

    savePushNotificationSettigs: thunk(async (actions, payload, helpers) => {
      const authStoreState = helpers.getStoreState().auth;
      const db = authStoreState.fbDb;
      const userId = authStoreState.user.uid;
      if (db) {
        if ((userId) && (payload)) {
          const usersRef = collection(db, "users");
          await setDoc(doc(usersRef, userId),
            {
              isSpudPushNotificationEnabled: payload.isSpudPushNotificationEnabled
            },
            { merge: true }
          );
        }
      }
      actions.setIsSpudPushNotificationEnabled(payload.isSpudPushNotificationEnabled)
    }),

    getPushNotificationSettigs: thunk(async (actions, payload, helpers) => {
      if (isApp() && !isPlatform("desktop")) {
        const currentStoreState = helpers.getStoreState();
        const db = currentStoreState.auth.fbDb;
        const userId = currentStoreState.auth.user.uid;
        if (db) {
          const docRef = doc(db, "users", userId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            if (("isSpudPushNotificationEnabled" in data))
              actions.setIsSpudPushNotificationEnabled(data.isSpudPushNotificationEnabled);
          }
        }
      }
    }),

    registerPushNotifications: thunk(async (actions) => {
      try {
        const result = await registerNotifications();
        if (result === "granted") {
          actions.setIsPushNotificationEnabled(true);
          actions.setIsPushNotificationInitialized(true)
          await PushNotifications.register();
          await actions.addNotificationsListeners();
          const payload = { isSpudPushNotificationEnabled: true }
          await actions.savePushNotificationSettigs(payload);
        }
        else {
          actions.setIsPushNotificationEnabled(false);
          actions.setIsPushNotificationInitialized(false)
          const payload = { isSpudPushNotificationEnabled: false }
          await actions.savePushNotificationSettigs(payload);
        }
        return result;
      } catch (error) {
        console.log(error)
      }
    }),

    addNotificationsListeners: thunk(async (actions, payload, helpers) => {
      await PushNotifications.addListener('registration', token => {
        const authActions = helpers.getStoreActions().auth;
        authActions.setPushNotificationToken(token.value);
      });

      // await PushNotifications.addListener('registrationError', err => {
      //   //alert(err.error);
      // });

      // await PushNotifications.addListener('pushNotificationReceived', notification => {
      //   //alert('Push notification received: ' + JSON.stringify(notification));
      // });

    }),

    setRigsStatus: action((state, payload) => {
      state.status.find((s) => s.statusDesc === payload.statusDesc).isSelected = payload.isSelected;
    }),

    setShowLicenses: action((state, showLicenses) => {
      if (state.showLicenses !== showLicenses) {
        state.showLicenses = showLicenses;
      }
    }),

    setShowWells: action((state, showWells) => {
      if (state.showWells !== showWells) {
        state.showWells = showWells;
      }
    }),

    setShowLandGrid: action((state, showLandGrid) => {
      if (state.showLandGrid !== showLandGrid) {
        state.showLandGrid = showLandGrid;
      }
    }),

    setSearchRadius: action((state, newSearchRadius) => {
      state.searchRadius = newSearchRadius;
    }),

    setProjDepthRangeMinValue: action((state, projDepthRangeMinValue) => {
      state.projDepthRangeMinValue = projDepthRangeMinValue;
    }),

    setProjDepthRangeMaxValue: action((state, projDepthRangeMaxValue) => {
      state.projDepthRangeMaxValue = projDepthRangeMaxValue;
    }),
  },
  {
    deny: [
      "projDepthRangeMinValue",
      "projDepthRangeMaxValue",
      "isPushNotificationEnabled",
      "isPushNotificationInitialized",
      "isSpudPushNotificationEnabled"
    ],
    storage: "localStorage",
  }
);

export const storeModel = {
  rigs: rigsModel,
  map: mapModel,
  activity: activityModel,
  search: searchModel,
  searchNative: searchNativeModel,
  auth: authModel,
  utils: utilsModel,
  settings: settingsModel,
  subscription: subscriptionModel,
  notifications: notificationModel
};

const svc_getRigs = async () => {
  let result = [];
  try {
    await Axios.get("rigs_api/rigs").then((results) => {
      let data = results.data;
      data.forEach((element) => {
        let newLocationItem = new mapItem(
          element.id,
          element.name,
          element.lat,
          element.lon,
          element.statusId,
          1 //itemType=1 means it is a rig
        );
        result.push(newLocationItem);
      });
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

let cancel;
const svc_getSearches = async (payload) => {
  cancel && cancel();

  let searchResult = [];
  //await axios.post("https://garigsapi.azure-api.net/search_api/search?searchVersion=1&search=pd 133",
  await Axios.post(Constants.AZURE_SEARCH_FUNC_URL + "Search",
    {
      //  .post("https://garigsapi.azure-api.net/search_api/search", {
      searchVersion: "3",
      search: payload.searchValue,
      filter: payload.searchFilter
    },
    {
      cancelToken: new axios.CancelToken(function executor(c) {
        cancel = c;
      }),
    }
  )
    .then((results) => {
      results.data.forEach((group) => {
        let items = [];
        group.items.forEach((item) => {
          let newLocationItem = new mapItemExt(
            item.id,
            item.name,
            item.yctr,
            item.xctr,
            item.statusId,
            item.type,
            item.distance,
            item.caption,
            null,
            null,
            null,
            item.fields
          );
          items.push(newLocationItem);
        });
        searchResult.push(new searchGroup(group.typeDesc, items));
      });
    })
    .catch(function (error) {
      if (axios.isCancel(error)) {
        //console.log('Request canceled', error.message);
      } else {
        console.log("Search Request Error", error.message);
      }
    });

  return searchResult;
};

const svc_getSearchFilters = async () => {
  let searchFiltersResult = [];
  await Axios.get(Constants.AZURE_SEARCH_FUNC_URL + "Filters")
    .then((results) => {
      let data = results.data;
      if (data) {
        searchFiltersResult = searchFiltersResult.concat(data);
      }
    })
    .catch(function (error) {
      if (axios.isCancel(error)) {
        //console.log('Request canceled', error.message);
      } else {
        console.log("Search Filters Request Error", error.message);
      }
    });
  return searchFiltersResult;
}

const svc_getRigInfo = async (id) => {
    let result;
  try {
    await Axios.get(
      `https://garigstablestoragefunctionapp20200310121522.azurewebsites.net/api/rig/${id}`
    ).then((results) => {
      let data = results.data;
      if (data) {
        data.itemType = 1; //itemType=1 means it is a rig
        result = new rigDetails(data);
      }
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

const svc_getRigWell = async (wellId) => {
  let resultRigWell;
  await Axios.post(Constants.CORE_SERVICE + "Rig/Well", {
    id: wellId,
  })
    .then((result) => {
      const item = result.data;
      if (item) {
        resultRigWell = new wellLicense(item);
        return resultRigWell;
      }
    })
    .catch(function (error) {
      console.log("well Request Error", error);
    });
  return resultRigWell;
};
//https://grsc.riglocations.ca/api/Rig/NearbyLic

const svc_getItemsInRadius = async (requestData) => {
  let resultData = [];
  await Axios.post(Constants.CORE_SERVICE + "Rig/NearbyWellsOrLic", {
    //await Axios.post("https://test.riglocations.ca/api/Rig/NearbyWellsOrLic", {
    lat: requestData.lat,
    lon: requestData.lon,
    radius: requestData.radius,
    minDepth: requestData.minDepth,
    maxDepth: requestData.maxDepth,
    extended: requestData.extended,
    entityTypes: requestData.entityTypes, //1:License,2:Wells,3:both
  })
    .then((result) => {
      const items = result.data;
      if (items) {
        items.forEach((item) => {
          resultData.push(
            new mapItem(
              item.id,
              item.wellUWI,
              item.lat,
              item.lon,
              null,
              item.itemType,
              item.distance
            )
          );
        });
        return resultData;
      } else return [];
    })
    .catch(function (error) {
      console.log("Search Request Error", error.message);
    });
  return resultData;
};


