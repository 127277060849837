import { thunk, action } from "easy-peasy";
import Axios from "../configs/Axios";
import {
    mapItemExt,
    searchGroup
} from "../models/MapItemsModels";
import * as Constants from "../utils/Constants";
import { UserSearch } from "../models/UserSearchModel";

const searchNativeModel = {
    // searchedItemIcons: [
    //   require("../assets/icons/location1x.png"),
    //   require("../assets/icons/location2x.png"),
    //   require("../assets/icons/location3x.png"),
    // ],
    searchText: "",
    searchedItem: null,
    //searchedItemAnnotation: null,
    searches: [],
    searchFilters: [],
    selectedSearchFilter: "All",
    //actions
    clearSearches: action((state) => {
        state.searches = [];
    }),
    setSearchText: action((state, searchText) => {
        state.searchText = searchText;
    }),
    setSearches: action((state, searches) => {
        state.searches = searches;
    }),
    setSearchFilters: action((state, searchFilters) => {
        state.searchFilters = searchFilters;
    }),
    setSelectedSearchFilter: action((state, selectedSearchFilter) => {
        state.selectedSearchFilter = selectedSearchFilter;
    }),
    setSearchedItem: action((state, searchedItem) => {
        state.searchedItem = searchedItem;
    }),
    setSearchedItemAnnotation: action((state, searchedItemAnnotation) => {
        state.searchedItemAnnotation = searchedItemAnnotation;
    }),
    //thunks//thunks
    getSearches: thunk(async (actions, payload) => {
        actions.setSearchText(payload.searchValue);
        const searches = await svc_getSearches(payload);
        actions.setSearches(searches);
    }),

    getSearchFilters: thunk(async (actions) => {
        const searchFilters = await svc_getSearchFilters();
        actions.setSearchFilters(searchFilters);
    }),

    saveSearchedItem: thunk(async (actions, item, helpers) => {
        if (item.itemType === 1) {
            const currentState = helpers.getStoreState();
            const db = currentState.auth.fbDb;
            if ((db) && ((currentState.auth.user) && currentState.auth.user.uid)) {
                const userSearch = new UserSearch({
                    uid: currentState.auth.user.uid,
                    itemId: item.id
                })
                await UserSearch.save(db, userSearch)
            }
        }
    })
};

let cancel;
const svc_getSearches = async (payload) => {
    cancel && cancel();

    let searchResult = [];
    //await axios.post("https://garigsapi.azure-api.net/search_api/search?searchVersion=1&search=pd 133",
    await Axios.post(Constants.AZURE_SEARCH_FUNC_URL + "Search",
        {
            //  .post("https://garigsapi.azure-api.net/search_api/search", {
            searchVersion: "3",
            search: payload.searchValue,
            filter: payload.searchFilter
        },
        {
            cancelToken: new Axios.CancelToken(function executor(c) {
                cancel = c;
            }),
        }
    )
        .then((results) => {
            results.data.forEach((group) => {
                let items = [];
                group.items.forEach((item) => {
                    let newLocationItem = new mapItemExt(
                        item.id,
                        item.name,
                        item.yctr,
                        item.xctr,
                        item.statusId,
                        item.type,
                        item.distance,
                        item.caption,
                        null,
                        null,
                        null,
                        item.fields
                    );
                    items.push(newLocationItem);
                });
                searchResult.push(new searchGroup(group.typeDesc, items));
            });
        })
        .catch(function (error) {
            if (Axios.isCancel(error)) {
                //console.log('Request canceled', error.message);
            } else {
                console.log("Search Request Error", error.message);
            }
        });

    return searchResult;
};

const svc_getSearchFilters = async () => {
    let searchFiltersResult = [];
    await Axios.get(Constants.AZURE_SEARCH_FUNC_URL + "Filters")
        .then((results) => {
            let data = results.data;
            if (data) {
                searchFiltersResult = searchFiltersResult.concat(data);
            }
        })
        .catch(function (error) {
            if (Axios.isCancel(error)) {
                //console.log('Request canceled', error.message);
            } else {
                console.log("Search Filters Request Error", error.message);
            }
        });
    return searchFiltersResult;
};
export default searchNativeModel;